
import '../css/switchboard.scss';

import 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import 'tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';

import { Controller } from './Controller';
import { Model } from './Model';
import { decode } from "jsonwebtoken";

Controller.init();


export async function authGuardWithToken() {
    try {
        const successfullyCreatedTimeout = await setNewTokenTimeout();
        if (!successfullyCreatedTimeout) throw new Error("Could not make timeout, and refresh failed");
    } catch (error) {
        console.error('Error in authGuardWithToken:', error);
        clearTokens();
        return null;
    }
}

async function setNewTokenTimeout() {
    let token = localStorage.getItem('token');
    if (!token) {
        console.log("No token")
        const refreshedTokens = await refreshTokens();
        if (!refreshedTokens) return null;
        token = localStorage.getItem('token');
    }
    const decoded = decode(token);
    const currentTime = Math.floor(Date.now());

    const timeout = ((decoded.exp * 1000) - currentTime);
    const buffer = 2 * 60 * 60 * 1000; // 2 hour attempt refresh before expiration date

    if (timeout <= buffer) {
        const refreshedTokens = await refreshTokens();
        if (!refreshedTokens) return null;
    }

    return setTimeout(async () => {
        try {
            const refreshedTokens = await refreshTokens();
            if (!refreshedTokens) throw new Error("Refresh Not successful")
            return setNewTokenTimeout()
        } catch (error) {
            console.error('Error refreshing token:', error);
            window.location.reload(true);
        }
    }, timeout - buffer);
}

async function refreshTokens() {
    try {
        const refresh = localStorage.getItem('refresh');
        if (!refresh || isExpired(refresh)) throw new Error("No Refresh Token or is expired");
        const response = await fetch(`${Model.serverDomain}/user/refresh_token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                refreshToken: localStorage.getItem('refresh'),
            })
        });

        if (response.ok) {
            const tokens = await response.json();
            localStorage.setItem('token', tokens.accessToken);
            localStorage.setItem('refresh', tokens.refreshToken);
        } else {
            throw new Error("Refresh failed.");
        }
        return true;
    } catch(err) {
        console.error(err);
        clearTokens();
        return null;
    }
}

function isExpired (token) {
    if (!token) return true;
    try {
        const decoded = decode(token);
        return decoded.exp <= Date.now(); // exp is prior to now, aka expired
    } catch (err) {
        console.error('Error decoding token:', err);
        return true;
    }
}

function clearTokens() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("refresh");
}